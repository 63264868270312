.absPosition {
  position: absolute;
  z-index: 1004;
}

.popupWindow {
  width: 100%;
  border-radius: var(--borderRadius);
  border: 1px solid var(--borderColor);
  box-sizing: border-box;
}
