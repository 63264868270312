.slider {
  position: relative;
  overflow: hidden;
  border-radius: var(--borderRadius);
  box-shadow: var(--basicShadow);
  border: 1px solid var(--borderColor);
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  form > * {
    margin-bottom: var(--spacer);
  }
}
.slide {
  width: 50%;
  flex-shrink: 0;
  padding: var(--spacer);
}
.flexSlides {
  display: flex;
  width: 200%;
  transition: all 0.5s;
  transform: translateX(0);
}
.move {
  transform: translateX(-50%);
}
