.root {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  > label {
    font-size: var(--fontSizeTiny);
    height: var(--inputLabelHeight);
    margin-bottom: calc(var(--spacer) / 2);
  }
  > input {
    width: 100%;
    display: block;
    border-radius: var(--borderRadius);
    border: var(--inputBorder);
    height: var(--componentHeight);
    padding-left: var(--inputPaddingLeft);
    padding-right: var(--inputPaddingRight);
    font-size: var(--fontSize);
    line-height: var(--componentHeight);
    outline: none;
    box-sizing: border-box;
    &::placeholder {
      color: var(--fgLightColor);
      font-weight: var(--fontWeight);
    }
    &:placeholder-shown {
      border-color: var(--fgLightColor);
    }
    &:focus {
      border-color: var(--primaryColor);
    }
    &:disabled {
      border-color: var(--disabledColor);
      color: var(--disabledColor);
    }
  }
  &.input--sm {
    > input {
      height: var(--componentHeightSm);
      line-height: var(--componentHeightSm);
    }
  }
  &.input--lg {
    > input {
      height: var(--componentHeightLg);
      line-height: var(--componentHeightLg);
    }
  }
}
.iconAfter {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: calc((var(--componentHeight) - 16px) / 2) ;
  right: calc((var(--componentHeight) - 16px) / 2) ;
  cursor: pointer;
  &.withLabel {
    top: calc(var(--inputLabelHeight) + calc(var(--spacer) / 2) + (var(--componentHeight) - 16px) / 2);
  }
  > svg {
      width: 16px;
      height: 16px;
  }
}

