.dropdownContainer {
    --inputPaddingLeft: calc(var(--items) * var(--multiSelectItemWidth) + calc(var(--items) + 1) * var(--spacer));
    position: relative;
    align-items: center;
    cursor: pointer;
    &.with_value {
        --inputBorder: 1px solid var(--borderColor);
    }
    &.closed {
        &:hover, &.focused {
            --inputBorder: 1px solid var(--borderColorHover);
        }
    }
    &.opened {
        --inputBorder: 1px solid var(--borderColorActive);
    }
}
.inputInDropdown {
    width: 100%;
    input {
        cursor: pointer;
    }
}
.row {
    height: var(--componentHeight);
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 var(--spacer);
    &:hover {
        background: var(--bgHoverColor);
    }
}
.chevronDown {
    width: 16px;
    height: 16px;
    transition: all 0.5s;
    > svg {
        width: 16px;
        height: 16px;
    }
    &.rotated {
        transform: rotateZ(180deg);
    }
}
.dropdownPopup {
    background: var(--bgColor);
}
.flexValues {
    display: flex;
    gap: var(--spacer);
    padding: calc(calc(var(--spacer) / 2) - 2px);
    position: absolute;
    bottom: 0;
    height: var(--componentHeight);
    z-index: 2;
}
.selectedItem {
    display: flex;
    gap: calc(var(--spacer) / 2);
    align-items: center;
    padding-inline: calc(var(--spacer) / 2);
    border: 1px solid var(--borderColorActive);
    border-radius: var(--borderRadius);
}
.renderedSelectedItem {
    width: var(--multiSelectItemWidth);
    overflow: hidden;
    text-overflow: ellipsis;
}
