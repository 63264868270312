.header {
  height: var(--componentHeightLg);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: calc(var(--spacer) * 2);
}
.logo {
  background: var(--primaryColor);
  width: 100px;
  height: var(--componentHeight);
  flex-shrink: 0;
}
.user {
  justify-self: flex-end;
  flex-shrink: 0;
}
.menu {
  width: 100%;
}
