.root {
    --componentHeight: 40px;
    --componentHeightSm: 28px;
    --componentHeightLg: 68px;
    --spacer: 12px;
    --fontSize: 14px;
    --fontSizeTiny: 12px;
    --fontWeight: 500;

    --bgColor: #ffffff;
    --bgAccentColor: #FCFCFD;
    --bgHoverColor: #F9FAFB;
    --darkColor: #101828;
    --fgColor: #344054;
    --fgLightColor: #667085;
    --disabledColor: #F8F9FC;
    --accentColor: #175CD3;
    --hoverColor: #3538CD;
    --warningColor: #C4320A;
    --errorColor: #F04438;
    --primaryColor: #7F56D9;
    --primaryAccentColor: #6941C6;
    color: var(--fgColor);
    * {
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        font-style: normal;
        font-variation-settings:
            "slnt" 0;
        font-smooth: always;
        -webkit-font-smoothing: antialiased;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    --borderRadius: 8px;
    --borderColor: var(--fgColor);
    --lightBorderColor: #EAECF0;
    --borderColorActive: var(--accentColor);
    --borderColorHover: var(--hoverColor);

    --basicShadow: 0px 0px 16px 0px rgba(125, 125, 125, 0.15);

    // the next example, how we could add separated variables for
    // each component, with dependencies from common variables
    --buttonHeight: var(--componentHeight);
    --buttonBorderRadius: var(--borderRadius);
    --buttonBorderWidth: 1px;
    --buttonBorderStyle: solid;
    --buttonBorderColor: var(--borderColor);
    --buttonActiveBorderColor: var(--borderColorActive);
    --buttonHoverBorderColor: var(--borderColorHover);
    --buttonFontSize: var(--fontSize);
    --buttonPaddingInline: var(--spacer);
    --buttonBgColor: var(--bgColor);
    --buttonActiveBgColor: var(--bgAccentColor);
    --buttonHoverBgColor: var(--bgHoverColor);
    --buttonLgHeight: var(--componentHeightLg);
    --buttonSmHeight: var(--componentHeightSm);
    --buttonColor: var(--fgColor);

    --inputBorder: 1px solid var(--borderColor);
    --inputPaddingLeft: var(--spacer);
    --inputPaddingRight: var(--spacer);
    --inputLabelHeight: 14px;

    --multiSelectItemWidth: 60px;

    --gridRowAir: 8px;
    --gridHeaderBg: #F9F5FF;

    --h1Size: 32px;
    --h2Size: 24px;
    --h3Size: 20px;
    --h4Size: 18px;

    h1 {
        font-size: var(--h1Size);
        margin-bottom: var(--spacer);
        color: var(--fgColor);
    }
    h2 {
        font-size: var(--h2Size);
        margin-bottom: var(--spacer);
        color: var(--fgColor);
    }
    h3 {
        font-size: var(--h3Size);
        margin-bottom: var(--spacer);
        color: var(--fgColor);
    }
    h4 {
        font-size: var(--h4Size);
        margin-bottom: var(--spacer);
        color: var(--fgColor);
    }
    p {
        font-size: var(--fontSize);
        color: var(--fgColor);
    }
    .p2 {
        font-size: var(--fontSizeTiny);
        color: var(--fgColor);
    }
    :global {
        .container {
            width: 100%;
            max-width: 1280px;
            margin: 0 auto;
            padding-inline: var(--spacer);
        }
        .flex {
            display: flex;
            width: 100%;
            gap: var(--spacer);
        }
        .column25 {
            width: calc(25% - var(--spacer) / 2);
        }
        .column75 {
            width: calc(75% - var(--spacer) / 2);
        }
    }

}
