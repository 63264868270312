.root {
  
}
.grid {
  display: grid;
  grid-template-columns: repeat(var(--columnCount),
    minmax(auto, calc(100% / var(--columnCount))));
}

.header {
  background: var(--gridHeaderBg);
  border-bottom: 1px solid var(--lightBorderColor);
  color: var(--darkColor);
  padding-inline: calc(var(--gridRowAir) * 3);
  padding-block: calc(var(--gridRowAir) * 2);
  word-break: break-all;
}
.gridRow {
  display: contents;
  > div {
    padding-inline: calc(var(--gridRowAir) * 3);
    padding-block: calc(var(--gridRowAir) * 2);
    word-break: break-all;
  }
  &.odd_row {
    > div {
      background: var(--bgAccentColor);
      border-top: 1px solid var(--lightBorderColor);
      border-bottom: 1px solid var(--lightBorderColor);
    }
  }
  &:hover {
    > div {
      background: var(--bgHoverColor);
    }
  }
}