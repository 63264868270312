:global(.global-no-body-scroll) {
  overflow: hidden !important;
}
.modalRoot {
  position: absolute;
  z-index: 4000;
  left: 0;
  top: 0;
}
.modalOverlay {
  display: block;
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: var(--app-height, 100vh);
  z-index: 1003;
  overflow-y: auto;
  background: rgba(0,0,0,0.3);
  &.opened {
    visibility: visible;
    &::before {
      opacity: 0.69;
    }
    .modalWindow {
      transform: translate(-50%, 0);
      top: 50px;
    }
  }
  .modalWindow {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 1000px));
    border-radius: var(--borderRadius);
    background: var(--bgColor);
    transition: all 0.5s;
    padding: var(--spacer);
    &Shadow {
      box-shadow: var(--basicShadow);
    }
  }
}
