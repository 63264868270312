.root {

}
.obj_item {
  display: flex;
  gap: var(--spacer);
}
.fields_not_empty {
  padding: var(--spacer);
  border-radius: var(--borderRadius);
  border: 1px solid var(--borderColorActive);
}
